import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { PageViewAnalytics } from '@unlikelystudio/react-ecommerce-hooks'

import { useTracker } from '~/providers/TrackerProvider'

import useGTMTracking from '~/hooks/tracking/useGTMTracking'

type TrackingProps = {
  template: Parameters<typeof PageViewAnalytics>[number]['template']
}

export default function Tracking({ template }: TrackingProps) {
  const tracker = useTracker()
  const { asPath, isFallback } = useRouter()

  useGTMTracking()

  useEffect(() => {
    if (!isFallback) {
      tracker.emit(TRACKING_EVENTS.PAGE_VIEW, asPath)
    }
  }, [])

  return <PageViewAnalytics pathname={asPath} template={template} />
}
