import { STORYBLOK_SLICES } from '~/lib/storyblok-types'

export const SLICES_WITH_PRIORITY = [
  STORYBLOK_SLICES.PRODUCT_TITLE_SLIDER,
  STORYBLOK_SLICES.PUSH_GRID,
  STORYBLOK_SLICES.PUSH_SLIDER,
  STORYBLOK_SLICES.PUSH_PRODUCTS,
  STORYBLOK_SLICES.HERO_MAIN,
  STORYBLOK_SLICES.HERO_BANNER,
  STORYBLOK_SLICES.BLOG_GRID,
  STORYBLOK_SLICES.LIST_WORKSHOPS,
  STORYBLOK_SLICES.PRODUCT_HEADER,
  STORYBLOK_SLICES.EDITORIAL_TWO_COLUMNS_IMAGE_TEXT,
  STORYBLOK_SLICES.EDITORIAL_IMAGES_COLUMNS,
  STORYBLOK_SLICES.EDITORIAL_SLIDER_IMAGES_WITH_CAPTIONS,
  STORYBLOK_SLICES.EDITORIAL_COLORED_BLOCK,
  STORYBLOK_SLICES.EDITORIAL_VIDEO,
  STORYBLOK_SLICES.EDITORIAL_ACCORDIONS,
  STORYBLOK_SLICES.MEDIA_GRID,
  STORYBLOK_SLICES.HORIZONTAL_MEDIA,
  STORYBLOK_SLICES.EDITORIAL_COLORED_BLOCK,
] as const
