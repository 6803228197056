import classnames from 'classnames/bind'
import { ComponentType, createContext, useContext } from 'react'
import { SLICES_WITH_PRIORITY } from '~/lib/slices-with-priority'
import { TStoryblokSliceKey } from '~/lib/storyblok-types'

import {
  SLICES_WITHOUT_MARGIN_BOTTOM,
  SLICES_WITHOUT_MARGIN_TOP,
} from '~/components/Page/slices-without-margin'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type TComponentType = ComponentType<any>
export type TSerializerType = (slice: any, locale: string, opts: any) => any

export type RenderComponentFromImportType = Partial<
  Record<TStoryblokSliceKey, TComponentType>
>
export type RenderSerializerFromImportType = Partial<
  Record<TStoryblokSliceKey, TSerializerType>
>

export interface SliceData {
  type: TStoryblokSliceKey
  data: ReturnType<TSerializerType>
}

export interface SliceManagerProps {
  slicesClassName?: string
  mapSliceTypeToComponent: RenderComponentFromImportType
  slicesData: SliceData[]
  withContextPosition?: boolean
}

interface SliceContextValue {
  position: number
}

const SliceContext = createContext<SliceContextValue>({ position: 0 })
export function SliceProvider({ children, value }: any) {
  return <SliceContext.Provider value={value}>{children}</SliceContext.Provider>
}

export function useSliceContext() {
  return useContext(SliceContext)
}

function SliceManager({
  slicesClassName,
  mapSliceTypeToComponent,
  slicesData,
  withContextPosition = true,
}: SliceManagerProps) {
  const withoutMarginTop =
    //@ts-ignore
    SLICES_WITHOUT_MARGIN_TOP.indexOf(slicesData?.[0]?.type) > -1
  const withoutMarginBottom =
    SLICES_WITHOUT_MARGIN_BOTTOM.indexOf(
      //@ts-ignore
      slicesData?.[slicesData.length - 1]?.type,
    ) > -1

  const firstPrioritySliceIndex = slicesData.findIndex(
    (slice) =>
      SLICES_WITH_PRIORITY.indexOf(
        //@ts-ignore
        slice?.type,
      ) > -1,
  )

  return (
    <div
      className={cx(css.pageContent, {
        withoutMarginTop,
        withoutMarginBottom,
      })}>
      {slicesData?.map((sliceData, index) => {
        const SliceComponent = mapSliceTypeToComponent?.[sliceData?.type]

        if (!SliceComponent) return null
        return (
          <SliceProvider
            key={index}
            value={{
              // we only need to set the first priority element for now
              position:
                withContextPosition && index === firstPrioritySliceIndex
                  ? 0
                  : false,
            }}>
            <SliceComponent
              key={index}
              className={slicesClassName}
              {...sliceData?.data}
            />
          </SliceProvider>
        )
      })}
    </div>
  )
}

SliceManager.defaultProps = {}

export default SliceManager
