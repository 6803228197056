import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import SliderControls from '~/components/Abstracts/SliderControls'
import SliderArrows from '~/components/SliderArrows'
import ImageWithCaption, {
  ImageWithCaptionProps,
} from '~/components/UI/ImageWithCaption'

import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialSliderImagesWithCaptionsProps {
  className?: string
  title?: RichTextBlock
  imagesWithCaption?: ImageWithCaptionProps[]
}

function EditorialSliderImagesWithCaptions({
  className,
  title,
  imagesWithCaption,
}: EditorialSliderImagesWithCaptionsProps) {
  const customSliderRef = useRef()
  const [{ slideIndex, setSlideIndex, nextSlide, prevSlide }, setSliderState] =
    useSliderState()
  const isFirstSlice = useIsFirstSlice()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24Primary,
  })
  const isMobile = useBreakpoint('md')

  return (
    <div className={cx(css.EditorialSliderImagesWithCaptions, className)}>
      {title && (
        <div className={gridStyle}>
          <RichText render={title} className={cx(css.title, titleStyle)} />
        </div>
      )}

      {imagesWithCaption?.length > 0 && (
        <>
          <div
            className={cx(css.sliderContainer, gridStyle)}
            ref={customSliderRef}>
            <Slider
              className={cx(css.slider)}
              customSliderRef={customSliderRef}
              maxSlideIndexChange={1}
              setSliderState={setSliderState}
              autoIsSlider={false}
              snap
              infinite>
              {imagesWithCaption?.map((image, index) => {
                const imageProps = {
                  ...image,
                  image: {
                    ...image?.image,
                    priority: isFirstSlice && index < 2,
                  },
                }
                return (
                  <ImageWithCaption
                    {...imageProps}
                    className={css.sliderItem}
                    key={`slider_item_${index}`}
                  />
                )
              })}
            </Slider>
            <div className={css.sliderArrowsContainer}>
              <SliderArrows
                layout="inside"
                buttonsLayout={'s'}
                theme={GlobalThemeColors.White}
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                shouldHide={!isMobile}
              />
            </div>
          </div>
          <div className={css.sliderControls}>
            <SliderControls
              length={imagesWithCaption?.length}
              className={css.mobileSliderControls}
              slideIndex={slideIndex}
              setSlideIndex={setSlideIndex}
            />
          </div>
        </>
      )}
    </div>
  )
}

EditorialSliderImagesWithCaptions.defaultProps = {}

export default EditorialSliderImagesWithCaptions
