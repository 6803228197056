import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { Nullish } from '~/@types/generic'
import { SINGLE_ROUTES, TRACKING_EVENTS } from '~/lib/constants'

import { UnlikelyCart } from '@unlikelystudio/commerce-connector'
import {
  useGetCart,
  useGetCustomer,
} from '@unlikelystudio/react-ecommerce-hooks'

import { useTracker } from '~/providers/TrackerProvider'

import useLang from '~/hooks/useLang'
import useLocale from '~/hooks/useLocale'

import { createCartUrl } from '~/utils/create-cart-url'
import { filterUndefinedKeys } from '~/utils/filter-undefined-keys'

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

export default function useGTMTracking() {
  const router = useRouter()
  const tracker = useTracker()
  const { data: cart } = useGetCart({ refetchOnMount: false })
  const eventsBuffer = useRef([])
  const [dataLayerIsAvailable, setDataLayerIsAvailable] =
    useState<boolean>(false)
  const languageCode = useLang()
  const locale = useLocale()
  const { data: profileData } = useGetCustomer()

  const pageBasics = {
    language: languageCode,
    user_logged_in: Boolean(profileData),
    basket_filled: cart?.lines?.length > 0,
    bastket_amount: cart?.cost?.totalAmount?.amount ?? null,
  }

  useEffect(() => {
    const checkDataLayer = setInterval(() => {
      if (window?.dataLayer) {
        setDataLayerIsAvailable(true)
        clearInterval(checkDataLayer)
      }
    }, 1000)

    return () => {
      clearInterval(checkDataLayer)
    }
  }, [])

  const getPageData = (): {
    page_type?: string
    page_title?: string
    page_path?: string
  } => {
    const uid = router.query.uid
    const route = router.route
    const realPath = router.asPath

    const defaultParams = {
      page_path: realPath,
    }

    switch (route) {
      case '/':
      case 'global':
        return {
          ...defaultParams,
          page_type: 'Homepage',
          page_title: 'Homepage',
        }
      case '/404':
        return {
          ...defaultParams,
          page_type: '404',
          page_title: '404',
        }
      case '/products/[uid]':
        return {
          ...defaultParams,
          page_type: 'Product',
          page_title: `${uid}`,
        }
      case '/categories/[uid]':
        return {
          ...defaultParams,
          page_type: 'Category',
          page_title: `${uid}`,
        }
      case '/contact':
        return {
          ...defaultParams,
          page_type: 'Contact',
          page_title: 'Contact',
        }
      case '/faq':
        return {
          ...defaultParams,
          page_type: 'FAQ',
          page_title: 'FAQ',
        }
      case '/privacy-policy':
        return {
          ...defaultParams,
          page_type: 'Privacy Policy',
          page_title: 'Privacy Policy',
        }
      case SINGLE_ROUTES.search.href:
        return {
          ...defaultParams,
          page_type: 'Search',
          page_title: 'Search',
        }
      case SINGLE_ROUTES.login.href:
        return {
          ...defaultParams,
          page_type: 'Login',
          page_title: 'Login',
        }
      case SINGLE_ROUTES.register.href:
        return {
          ...defaultParams,
          page_type: 'Register',
          page_title: 'Register',
        }
      case SINGLE_ROUTES.forgotPassword.href:
        return {
          ...defaultParams,
          page_type: 'Forgot password',
          page_title: 'Forgot password',
        }
      case SINGLE_ROUTES.resetPassword.href:
        return {
          ...defaultParams,
          page_type: 'Reset password',
          page_title: 'Reset password',
        }
      case SINGLE_ROUTES.enableAccount.href:
        return {
          ...defaultParams,
          page_type: 'Enable account',
          page_title: 'Enable account',
        }
      case SINGLE_ROUTES.account.href:
        return {
          ...defaultParams,
          page_type: 'Account',
          page_title: `Account${
            router.query.section ? ` ${router.query.section}` : ''
          }`,
        }
      default:
        return {
          page_type: 'Universal',
          page_path: realPath,
          page_title: `${uid}`,
        }
    }
  }

  const pushToDataLayer = (event: any) => {
    // Uncomment to debug
    // console.log({ gtmReady, event })

    // Note :
    // Commented for now waiting for the correct event name

    const filteredEvent = filterUndefinedKeys(event)

    if (window?.dataLayer?.push) {
      window.dataLayer.push(filteredEvent)
    } else {
      eventsBuffer.current.push(filteredEvent)
    }

    // if (window?.dataLayer?.push) {
    //   window.dataLayer.push(event)
    // }

    // Uncomment to debug
    // console.log({ eventsBuffer: eventsBuffer.current })
    // console.log({ dataLayer: window.dataLayer })
  }

  useEffect(() => {
    if (dataLayerIsAvailable && eventsBuffer.current.length > 0) {
      eventsBuffer.current.forEach((event) => {
        pushToDataLayer(event)
      })

      eventsBuffer.current = []
    }
  }, [dataLayerIsAvailable])

  const eventReducer = (event: string, eventPayload: any) => {
    switch (event) {
      case TRACKING_EVENTS.PAGE_VIEW:
        pushToDataLayer({
          event: 'page_view',
          ...pageBasics,
          ...getPageData(),
        })
        break
      case TRACKING_EVENTS.PRODUCT_ADD_TO_CART:
        pushToDataLayer({
          event,
          ecommerce: {
            cart_url: createCartUrl(),
            items: eventPayload,
          },
          _clear: true,
        })
        break
      case TRACKING_EVENTS.VIEW_ITEM_RECENTLY:
      case TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART:
      case TRACKING_EVENTS.PRODUCT_ADD_TO_WISHLIST:
      case TRACKING_EVENTS.PRODUCT_DETAIL:
      case TRACKING_EVENTS.PRODUCT_IMPRESSION_LIST:
      case TRACKING_EVENTS.PRODUCT_CLICK:
      case TRACKING_EVENTS.PROMOTION_VIEW:
      case TRACKING_EVENTS.PROMOTION_CLICK:
      case TRACKING_EVENTS.CHECKOUT_BEGIN:
      case TRACKING_EVENTS.OPEN_CART:
        pushToDataLayer({
          event,
          ecommerce: {
            items: eventPayload,
          },
          _clear: true,
        })
        break
      case TRACKING_EVENTS.CONTACT_FORM_SUBMIT:
      case TRACKING_EVENTS.REVIEW_SUBMITTED:
        pushToDataLayer({
          event,
          form_name: eventPayload?.formName,
        })
        break
      case TRACKING_EVENTS.SEARCH:
        pushToDataLayer({
          event,
          results: eventPayload?.results,
          search_term: eventPayload?.searchTerm,
          clicked_url: eventPayload?.clickedUrl,
        })
        break
      case TRACKING_EVENTS.ALERT_SUBSCRIPTION:
      case TRACKING_EVENTS.LOGIN:
      case TRACKING_EVENTS.LEAVE_REVIEW_CTA:
      case TRACKING_EVENTS.NEWSLETTER_SUBSCRIPTION:
      case TRACKING_EVENTS.SIGN_UP:
        pushToDataLayer({
          event,
          email: eventPayload?.email,
          first_name: eventPayload?.firstName,
          last_name: eventPayload?.lastName,
        })
        break
      case TRACKING_EVENTS.CTA_CLICKED:
        pushToDataLayer({
          event,
          clicked_label: eventPayload?.label,
          clicked_url: eventPayload?.url,
        })
        break
      case TRACKING_EVENTS.PRODUCT_FILTER:
        pushToDataLayer({
          event,
          list_id: eventPayload?.listId,
          filters: eventPayload?.filters,
          sorts: eventPayload?.sorts,
          ecommerce: {
            items: eventPayload?.products,
          },
        })
        break
      case TRACKING_EVENTS.SEARCH_CATEGORY_CLICK:
        pushToDataLayer({
          event,
          category: eventPayload?.category,
        })
        break
      case TRACKING_EVENTS.SEARCH_RESULT_CLICKED:
        pushToDataLayer({
          event,
          clicked_url: eventPayload?.url,
        })
        break
    }
  }

  useEffect(() => {
    tracker.on('*', eventReducer)
    return () => {
      tracker.off('*', eventReducer)
    }
  })
}
