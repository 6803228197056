import { Nullish } from '~/@types/generic'

export function filterUndefinedKeys<T extends object>(
  obj: Nullish<T>,
): Partial<T> {
  if (!obj) return {}

  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  ) as Partial<T>
}
