import { QUERY_PARAMS } from '~/lib/constants'

import getAlternateUrl from '~/utils/alternate-url'
import { applySearchParams } from '~/utils/apply-searchparams'

export function createCartUrl() {
  const searchParams = new URLSearchParams()
  searchParams.set(QUERY_PARAMS.CART.key, QUERY_PARAMS.CART.value)

  return getAlternateUrl(applySearchParams('', searchParams))
}
