import { Nullish } from '~/@types/generic'

export function applySearchParams(
  url: string,
  searchParams: Nullish<URLSearchParams | string>,
) {
  let endUrl = ''

  if (typeof searchParams === 'string' && searchParams.length > 0) {
    endUrl = `?${searchParams}`
  }

  if (searchParams instanceof URLSearchParams) {
    const stringifiedParams = searchParams.toString()

    if (stringifiedParams.length > 0) {
      endUrl = `?${stringifiedParams}`
    }
  }

  return `${url}${endUrl}`
}
